<template>

    <Head>
        <title>{{ page.meta_title }}</title>
        <meta name="description" v-if="page.meta_description" :content="page.meta_description">
    </Head>

    <Header :menu_items="menu_items" :page="page" :editable="editable" :website="website"/>


    <section class="text-gray-600 body-font section-text">
        <div class="bg-white dark:bg-black dark:text-white">
            <div>
                <main class="mx-auto max-w-7xl px-2 sm:px-6 lg:px-8">
                    <div class="flex flex-wrap px-1 pb-5 pt-10">
                        <div class="sm:w-2/3 w-full ">
                            <slot name="page_left">
                            </slot>
                        </div>

                        <div class="sm:w-1/3 w-full ">
                            <div
                                class="  text-left rounded border shadow-lg p-3 mt-10 sm:ml-5 background-custom-blue leading-10 font-bold text-black">
                                <slot name="page_right_orange">
                                </slot>
                            </div>
                            <slot name="page_right">
                            </slot>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    </section>

    <Footer :menu_items="menu_items" :page="page" :website="website">
        <template #content_website_footer_left>
            <slot name="content_website_footer_left">
            </slot>
        </template>
        <template #content_website_footer_center>
            <slot name="content_website_footer_center">
            </slot>
        </template>
        <template #content_website_footer_right>
            <slot name="content_website_footer_right">
            </slot>
        </template>
    </Footer>
</template>


<script>

import Header from '@/Pages/Admin/Page/Templates/Partials/Header.vue';
import Footer from '@/Pages/Admin/Page/Templates/Partials/Footer.vue';
import {Head} from "@inertiajs/vue3";

export default {

    components: {
        Header,
        Footer,
        Head
    },
    props: {
        menu_items: Object,
        page: Object,
        editable: Boolean,
        website: Object,
    },

    data() {
        return {}
    },
    methods: {
        itemsContains(n) {
            return this.page.page_options.indexOf(n) > -1
        }
    }
}
</script>

